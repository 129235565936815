<template>
  <main class="md:p-8">
    <sqr-page-header class="p-4 md:px-0" title="Relations">
      <router-link
        :to="{name: 'relations-add', params: {bid}}"
      >
        <sqr-button icon="user-plus" label="Nouvelle relation"/>
      </router-link>
    </sqr-page-header>

    <sqr-error-banner :error="loadError" />
    <sqr-progress v-show="loading" />

    <div class="py-2 flex justify-end">
      <sqr-input-checkbox
        placeholder="relations_include_archived"
        v-model="archived"
        @change="archiveChange($event)"
      />
    </div>

    <div class="flex flex-col">
      <div
        class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
      >
        <div
          class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th>{{ $t('relation_updated') }}</th>
                <th>{{ $t('relation_name') }}</th>
                <th>{{ $t('relation_birth_date') }}</th>
                <th>{{ $t('relation_address') }}</th>
                <th>{{ $t('relation_status') }}</th>
                <th>{{ $t('relation_manager') }}</th>
                <th>{{ $t('relation_assistant') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                :key="relation._id"
                v-for="relation in records"
                :class="{ strikeout: relation.archived }"
              >
                <td style="word-wrap: nowrap;">
                  {{ relation.updated | relative }}
                </td>
                <td class="font-medium">
                  <sqr-router-link
                    :label-raw="relation.name || 'N/A'"
                    to-name="relation-general"
                    :to-bid="bid"
                    :to-rid="relation.id"
                    is-link
                  />
                </td>
                <td>{{ relation.birthDate | date }}</td>
                <td>
                  {{ relation.address && relation.address.addressLocality }}
                </td>
                <td>
                  {{ $t('relation_status_' + relation.status) }}
                </td>
                <td>{{ relation.manager }}</td>
                <td>{{ relation.assistant }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <sqr-fire-page
      class="pt-4"
      v-bind="pagination"
      @page-set="pageSet"
      @page-size="pageSize"
    />
  </main>
</template>


<style lang="postcss" scoped>
th {
  @apply px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider;
}
tr:nth-child(odd) {
  @apply bg-white;
}
tr:nth-child(even) {
  @apply bg-gray-50;
}
td {
  @apply px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900;
}
</style>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import SqrButton from '@/sqrd-ui/SqrButton';
import SqrErrorBanner from '@/sqrd-ui/SqrErrorBanner';

import SqrInputCheckbox from '@/sqrd/SqrInputCheckbox';
import SqrFirePage from '@/sqrd/SqrFirePage';

import date from '@/filters/date';
import relative from '@/filters/relative';

export default {
  name: 'Relations',
  components: { SqrButton, SqrErrorBanner, SqrInputCheckbox, SqrFirePage },
  filters: { date, relative },
  props: {
    bid: String
  },
  computed: {
    ...mapGetters('brokers', ['broker']),
    ...mapState('relations', ['records', 'loading', 'loadError']),
    ...mapGetters('relations', ['pagination']),
    whereVal() {
      return this.archived ? [] : [['archived', '==', false]];
    }
  },
  data() {
    return {
      archived: false
    };
  },
  mounted() {
    this.reload();
  },
  methods: {
    async reload() {
      await this.sub({
        path: ['brokers', this.bid, 'relations'],
        where: this.whereVal,
        orderBy: [['updated', 'desc']],
        limit: 10
      });
    },
    ...mapActions('relations', ['sub', 'pageSet', 'pageSize', 'where']),
    archiveChange(value) {
      console.log(this.whereVal);
      this.where({ where: this.whereVal });
    }
  }
};
</script>
